import { Modal } from 'Shared/modal';
import { Tooltip } from 'Shared/tooltips';

onDomReady(function () {
   when($$('.releaseDetailsToggle'), function (el) {
      el.addEvent('click', function (ev) {
         ev.preventDefault();
         let releaseNotes = $$('.detailsContainer'),
            toggleText = el.get('data-toggleText'),
            newToggleText = el.get('text');

         el.set('data-toggleText', newToggleText);
         el.set('text', toggleText);

         releaseNotes.toggle();
      });
   });

   when($('releaseVersionSelect'), function (releaseSelect) {
      releaseSelect.addEvent('valuechanged', function (select) {
         // Default the value to undefined so URI.setData() removes the
         // parameter if HEAD should be displayed.
         let releaseid = select.get('data-value') || undefined;
         let releaseUri = new URI(window.location);

         releaseUri.clearData();
         releaseUri.setData('release', releaseid);

         let option = select.getElement('.currently-selected-option');
         if (option && option.hasClass('release-version-upcoming')) {
            releaseUri.setData('trainingVersion', 1);
         }

         window.location = releaseUri;
      });
   });

   when($E('.version-type'), function (el) {
      new Tooltip(el, {
         autoshow: true,
         showDelay: 400,
         side: 'bottom',
         text: new Element('p.version-type-tooltip', {
            text: el.getElement('.tooltip-text').get('text'),
         }),
         offset: -12,
         maxWidth: 150,
         arrowAlign: 'center',
      });
   });

   when($E('.draft-available'), function (el) {
      new Tooltip(el, {
         autoshow: true,
         showDelay: 400,
         side: 'bottom',
         text: new Element('p.draft-available-tooltip', {
            text: el.getElement('.tooltip-text').get('text'),
         }),
         offset: -12,
         maxWidth: 150,
         arrowAlign: 'center',
      });
   });

   when($('cancel-release-button'), el => {
      el.addEvent('click', ev => {
         ev.stop();
         Modal.open({
            type: 'message',
            /* Translators: %1 -> Name of the release version; */
            message: _js('Are you sure you want to cancel Release: %1?', App.releaseTitle),
            buttons: {
               [_js('Yes')]: () => {
                  const request = new Request.API_2_0(App.cancelReleaseEndpoint, {
                     method: 'delete',
                     onSuccess: () => window.location.reload(),
                  }).send();
                  LoadingIndicator.withPromise(request, {
                     pendingMessage: _js('Cancelling Release . . .'),
                     useErrorResponseAsFailureMessage: true,
                     duration: 10000,
                  });
               },
            },
         });
      });
   });

   // Delete redirected_to_latest GET param.
   if (getURLParameter('redirected_to_latest')) {
      const uri = new URI(window.location.href);

      let getParams = uri.getData();
      delete getParams.redirected_to_latest;

      const newUri = uri.setData(getParams);
      history.replaceState(null, '', newUri.toString());
   }
});
